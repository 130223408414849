<script>
import GeneeaTagsNewView from './GeneeaTagsNewView'
import NotifyService from '@/services/NotifyService'

export default {
  name: 'GeneeaTagEditView',
  extends: GeneeaTagsNewView,
  methods: {
    async getTag () {
      try {
        await this.$store.dispatch('tag/fetchOneGeneea', this.$route.params.id)
        const fetchedTag = this.$store.getters['tag/geneeaDetail']
        fetchedTag.imageId = fetchedTag.image?.id
        this.tag = fetchedTag
        if (Array.isArray(fetchedTag.relatedArticlesIds) && fetchedTag.relatedArticlesIds.length > 0) {
          this.relatedArticles = await this.$store.dispatch('tag/fetchArticles', fetchedTag.relatedArticlesIds)
        }
      } catch (error) {
        console.error(error)
      }
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('tag/update', { record: this.tag, tagType: 'geneea' })
        .then(() => {
          this.tag = this.$store.getters['tag/geneeaDetail']
          if (this.$store.getters['tag/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
            this.$router.push('/commonTags')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['tag/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.showEditComponents = true
    this.getTag()
  }
}
</script>
